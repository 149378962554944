//library
import React, { ReactElement } from 'react';
import Image from 'next/image';

const Maintenance = (): ReactElement => {
    const startMaintenanceTime = process.env.NEXT_PUBLIC_START_MAINTENANCE_TIME;
    const endMaintenanceTime = process.env.NEXT_PUBLIC_END_MAINTENANCE_TIME;
    return (
        <div className="flex flex-col h-screen w-full items-center px-4 justify-center text-elements">
            <Image src="/solarai_logo.png" alt="GetSolar AI" width="240" height="55" />
            {(!startMaintenanceTime || !endMaintenanceTime) && (
                <p className="text-lg md:text-xl lg:text-2xl text-center mt-6">
                    Admin is currently undergoing maintenance.
                </p>
            )}

            {startMaintenanceTime && endMaintenanceTime && (
                <p className="text-lg md:text-xl lg:text-2xl text-center mt-6">
                    Admin is currently undergoing scheduled maintenance from {startMaintenanceTime}{' '}
                    to {endMaintenanceTime}.
                </p>
            )}
            <p className="text-base md:text-lg lg:text-xl text-center mt-2">
                We apologise for any inconvenience caused.
            </p>
        </div>
    );
};

export default Maintenance;
