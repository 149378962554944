//library
import { useReducer } from 'react';

//store
import * as store from '@components/dashboard/Activity/app/controllers/store';

//types
import {
    UseControllersProps,
    UseMainCtrlReturn,
    UseControllersReturn,
    UseToolbarCtrlReturn
} from '@components/dashboard/Activity/interfaces/app/controllers/useControllers';

//code
import useMain from '@components/dashboard/Activity/app/controllers/controller/useMain';

/**
 * The controller
 *
 * @param {UseControllersProps} props - The models and helper controllers
 * @returns {UseControllersReturn} - The controller functions
 */
const useControllers = ({
    model: { activityModel },
    lib: {
        model: {
            resolver: { formatCountryCodeRsl },
            model: { salesModel }
        },
        external: { query, router }
    }
}: UseControllersProps): UseControllersReturn => {
    const countryCode = formatCountryCodeRsl(router.locale);

    const [state, dispatch] = useReducer(store.reducer, store.initialState);

    const {
        submitToolbarForm,
        toolbarInitialValues,
        activityStatusTitles,
        durationStatusTitles,
        summaryStatistics
    } = activityModel;

    /**
     * The main controller
     *
     * @returns {UseMainCtrlReturn} - The state values and main data.
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        useMain({ activityModel, salesModel, state, query, dispatch, countryCode });

        return {
            state,
            activityStatusTitles,
            durationStatusTitles,
            summaryStatistics
        };
    };

    /**
     * The controller of Toolbar module
     *
     * @returns {UseToolbarCtrlReturn} - The toolbar form values and functions.
     */
    const useToolbarCtrl = (): UseToolbarCtrlReturn => {
        return { initialValues: toolbarInitialValues, submit: submitToolbarForm };
    };

    return { useMainCtrl, useToolbarCtrl };
};

export default useControllers;
