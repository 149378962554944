//api
import { PRODUCT } from '@interfaces/graphql/API';

//code
import { List, SelectOption } from '@components/lib/interfaces/index';

export const residentialProduct: List = {
    [PRODUCT.RTO]: {
        value: PRODUCT.RTO,
        label: 'RTO',
        textColorHex: '#3C90E5',
        textColor: 'text-rto',
        selectedColorHex: '#3C90E526',
        selectedColor: 'bg-light-rto text-rto'
    },
    [PRODUCT.QC]: {
        value: PRODUCT.QC,
        label: 'QC',
        textColorHex: '#E53CD4',
        textColor: 'text-qc',
        selectedColorHex: '#E53CD426',
        selectedColor: 'bg-light-qc text-qc'
    },
    [PRODUCT.DP]: {
        value: PRODUCT.DP,
        label: 'DP',
        textColorHex: '#9556FF',
        textColor: 'text-dp',
        selectedColorHex: '#9556FF26',
        selectedColor: 'bg-light-dp text-dp'
    }
};

export const commercialProduct: List = {
    [PRODUCT.PPA]: {
        value: PRODUCT.PPA,
        label: 'PPA',
        textColorHex: '#FF38CB',
        selectedColorHex: '#FF38CB26'
    },
    [PRODUCT.DP]: {
        value: PRODUCT.DP,
        label: 'DP',
        textColorHex: '#9556FF',
        selectedColorHex: '#9556FF26'
    }
};

export const customerProduct: List = {
    [PRODUCT.RTO]: {
        value: PRODUCT.RTO,
        label: 'RTO',
        textColorHex: '#3C90E5',
        textColor: 'text-rto',
        selectedColorHex: '#3C90E526',
        selectedColor: 'bg-light-rto text-rto'
    },
    [PRODUCT.DP]: {
        value: PRODUCT.DP,
        label: 'DP',
        textColorHex: '#9556FF',
        textColor: 'text-dp',
        selectedColorHex: '#9556FF26',
        selectedColor: 'bg-light-dp text-dp'
    }
};

export const listResidentialProduct: SelectOption[] = Object.values(residentialProduct);

export const listCommercialProduct: SelectOption[] = Object.values(commercialProduct);

export const listCustomerProduct: SelectOption[] = Object.values(customerProduct);
